import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './css/Register.css';
import Footer from './components/Footer';
import './css/Popup.css'; // Import the new CSS file for popup styles

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
          try {
              const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
            if (response.status === 200 && response.data.status === 'success') {
                navigate('/');
            } else {
                localStorage.removeItem('token');
            }
        } catch (error) {
            console.error('Token validation error:', error);
            localStorage.removeItem('token');
        }
      }
    };

    validateToken();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, {
        email,
        password,
      });

      if (response.status === 200 && response.data.status === 'success') {
        showPopup('Your account has been successfully created.');
      } else if (response.status === 200 && response.data.message === 'User already exists') {
        showPopup('An account with this email address already exists.');
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const showPopup = (message) => {
    setPopupMessage(message);
    setPopupOpen(true);
  };

  const handlePasswordInput = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (newPassword.length < 8) {
      e.target.setCustomValidity('Password must be at least 8 characters long');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleConfirmPasswordBlur = (e) => {
    if (password !== confirmPassword) {
      e.target.setCustomValidity('Passwords do not match');
    } else {
      e.target.setCustomValidity('');
    }
  };

  const handleConfirmPasswordInput = (e) => {
    if (password !== e.target.value) {
      e.target.setCustomValidity('Passwords do not match');
    } else {
      e.target.setCustomValidity('');
    }
  };

  return (
      <div className='register-page'>
        <div className='content'>
          <h1 className='pycommerce-title'>
            <a href="/">PyCommerce</a>
          </h1>
          <div className='register-container'>
            <h2>Create Account</h2>
            <form onSubmit={handleSubmit}>
              <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!validateEmail(e.target.value)) {
                      e.target.setCustomValidity('Please enter a valid email address (e.g., example@domain.com)');
                    } else {
                      e.target.setCustomValidity('');
                    }
                  }}
                  required
              />
              <input
                  type="password"
                  placeholder="Password (min 8 characters)"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordInput}
                  minLength="8"
                  required
              />
              <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={handleConfirmPasswordBlur}
                  onInput={handleConfirmPasswordInput}
                  required
              />
              <button type="submit">Create your PyCommerce account</button>
            </form>
            <p>
              By creating an account, you agree to PyCommerce's Conditions of Use and Privacy Notice.
            </p>
            <div className="login-link">
              Already have an account? <Link to="/login">Sign in</Link>
            </div>
          </div>
        </div>
        <Footer />
        <Popup
            open={popupOpen}
            closeOnDocumentClick
            onClose={() => setPopupOpen(false)}
        >
          <div className="modal">
            <h2 className="modal-header">Registration Status</h2>
            <div className="modal-content">
              {popupMessage}
            </div>
            <div className="modal-actions">
              <div className="modal-login-link">
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </Popup>
      </div>
  );
};

export default Register;