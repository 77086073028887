import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/Product.css';
import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import './css/Notification.css';
import { useNavigate } from 'react-router-dom';
const Product = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/${id}`);
                if (response.status === 200 && response.data.status === 'success') {
                    setProduct(response.data.product);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false);
            }

        };
        fetchProduct();
    }, [id]);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    setIsLoggedIn(true);
                    return token;
                } else {
                    localStorage.removeItem('token');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
            }
        }
        return null;
    };

    useEffect(() => {
        validateToken();
    }, []);

    const handleAddToCart = async () => {
        const token = await validateToken();
        if (!token) {
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                token,
                product_id: id,
                quantity: 1
            });

            if (response.data.status === 'success') {
                setNotificationMessage('Item Added');
                setNotificationVisible(true);

                const button = document.querySelector('.product-page-add-to-cart-button');
                if (button) {
                    button.classList.add('cart-button-super-animate');
                    setTimeout(() => {
                        button.classList.remove('cart-button-super-animate');
                    }, 1500);
                }

                setTimeout(() => {
                    setNotificationVisible(false);
                }, 3000);
            } else if (response.data.status === 'failure' && response.data.error === -2) {
                setNotificationMessage('You can\'t purchase your own product.');
                setNotificationVisible(true);
            } else {
                console.error('Failed to add product to cart:', response.data.message);
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    if (loading) {
        return <div></div>;
    }
    
    return (
        <div className="product-page-container">
            <Header requireSearchBar={false} />
            {notificationVisible && (
                <div className={`notification ${notificationVisible ? 'show' : ''}`}>
                    {notificationMessage}
                </div>
            )}
            <div className="product-page-content">
                <div className="product-page-image-container">
                    <img src={product.image} alt={product.name} className="product-page-image" />
                </div>
                <div className="product-page-details">
                    <h1 className="product-page-name">{product.name}</h1>
                    <p className="product-page-description">{product.description}</p>
                    {product.stock > 0 && (
                        <p className="product-page-stock-remaining">Stock Remaining: {product.stock}</p>
                    )}
                    {product.stock === 0 && (
                        <p className="product-page-stock-remaining">Out of Stock</p>
                    )}
                    <p className="product-page-price">${product.price.toFixed(2)}</p>
                    {isLoggedIn && product.stock > 0 && (
                        <button className="product-page-add-to-cart-button" onClick={handleAddToCart}>
                            <FaCartPlus />
                            Add to Cart
                        </button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Product;