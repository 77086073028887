import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FaSearch, FaBars} from 'react-icons/fa';
import Popup from 'reactjs-popup';
import axios from "axios";
import '../css/Popup.css';

const Header = ({setSearchQuery, requireSearchBar}) => {
    const [popupMessage, setPopupMessage] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [localSearchQuery, setLocalSearchQuery] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, {token});
                    if (response.status === 200 && response.data.status === 'success') {
                        setIsLoggedIn(true);
                    } else {
                        localStorage.removeItem('token');
                        setIsLoggedIn(false);
                    }
                } catch (error) {
                    console.error('Token validation error:', error);
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                }
            }
            setLoading(false);
        };

        validateToken();
    }, []);

    const handleSearch = () => {
        setSearchQuery(localSearchQuery);
    };

    const handleLogout = () => {
        showPopup("Are you sure you want to logout?");
    };

    const showPopup = (message) => {
        setPopupMessage(message);
        setPopupOpen(true);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="header-top">
                <div className="header-left">
                    <h1 className="pycommerce-title">
                        <a href="/">PyCommerce</a>
                    </h1>
                </div>
                <div className="header-right">
                    <FaBars className="hamburger-icon" onClick={toggleMenu} />
                    <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
                        {!loading && (
                            isLoggedIn ? (
                                <>
                                    <Link to="/cart" className="nav-link header-button">Cart</Link>
                                    <Link to="/products" className="nav-link header-button">My Products</Link>
                                    <Link to="/additem" className="nav-link header-button">Add Item</Link>
                                    <Link to="/myorders" className="nav-link header-button">My Orders</Link>
                                    <Link to="/fulfillorders" className="nav-link header-button">Fulfill Orders</Link>
                                    <Link onClick={handleLogout} className="nav-link header-button">Logout</Link>
                                </>
                            ) : (
                                <>
                                    <Link to="/login" className="nav-link header-button">Login</Link>
                                    <Link to="/register" className="nav-link header-button">Register</Link>
                                </>
                            )
                        )}
                    </nav>
                </div>
            </div>
            {requireSearchBar && (
                <div className="header-bottom">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search products..."
                            value={localSearchQuery}
                            onChange={(e) => setLocalSearchQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                        <button onClick={handleSearch} className="search-button">
                            <FaSearch/>
                        </button>
                    </div>
                </div>
            )}
            <Popup
                open={popupOpen}
                closeOnDocumentClick
                onClose={() => setPopupOpen(false)}
            >
                <div className="modal">
                    <h2 className="modal-header">Login Status</h2>
                    <div className="modal-content">
                        {popupMessage}
                    </div>
                    <div className="modal-actions">
                        <div className="modal-login-link modal-button">
                            <Link onClick={() => {
                                localStorage.removeItem('token');
                                window.location.reload();
                            }}>Logout</Link>
                        </div>
                    </div>
                </div>
            </Popup>
        </header>
    );
};

export default Header;