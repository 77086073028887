import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/MyOrders.css';
import Header from './components/Header';
import Footer from './components/Footer';

const MyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    return token;
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
        return null;
    };

    const formatStatus = (status) => {
        console.log(status);
        if (status === 'processing') {
            return 'Processing';
        } else if (status === 'shipped') {
            return 'Shipped';
        } else if (status === 'delivered') {
            return 'Delivered';
        } else if (status === 'partly_shipped') {
            return 'Partly Shipped';
        } else if (status === 'partly_delivered') {
            return 'Partly Delivered';
        }
    }

    useEffect(() => {
        const fetchOrders = async () => {
            const token = await validateToken();
            if (!token) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders`, {
                    params: { token }
                });
                if (response.status === 200 && response.data.status === 'success') {
                    setOrders(response.data.orders);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [navigate]);

    if (loading) {
        return <div></div>;
    }

    return (
        <div id="my-orders-page">
            <Header requireSearchBar={false} />
            <div id="my-orders-content">
                <h1>Your Orders</h1>
                {orders.length > 0 ? (
                    <div id="orders-list">
                        {orders.map(order => (
                            <div key={order.order_id} className="order-item" onClick={() => navigate(`/order/${order.order_id}`)}>
                                <p><strong>Order ID:</strong> {order.order_id}</p>
                                <p><strong>Status:</strong> <span className={`order-status ${order.status}`}>{formatStatus(order.status)}</span></p>
                                <p><strong>Order Date:</strong> {new Date(order.created_at).toLocaleDateString()}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>You have no orders.</p>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default MyOrders;

