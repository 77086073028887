import axios from 'axios';
import './css/Popup.css';
import './css/Products.css';
import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [updatePopupOpen, setUpdatePopupOpen] = useState(false);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [deleteButton, setDeleteButton] = useState(false);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    return token;
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
        return null;
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        const token = await validateToken();
        if (!token) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/user`, {
                params: { token }
            });
            setProducts(response.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    }

    const updateProduct = async (product) => {
        setSelectedProduct(product);
        setUpdatePopupOpen(true);
    };

    const confirmUpdate = async () => {
        const token = await validateToken();
        if (token) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/products/${selectedProduct._id}`, selectedProduct, {
                    params: { token }
                });
                if (response.status === 200) {
                    setNotificationMessage('Product updated successfully');
                    setNotificationVisible(true);
                    setTimeout(() => {
                        setNotificationVisible(false);
                    }, 3000);
                    fetchProducts();
                } else {
                    console.error('Failed to update product:', response.data.message);
                }
            } catch (error) {
                console.error('Error updating product:', error);
            }
        }
        setUpdatePopupOpen(false);
    };

    const deleteProduct = async (productId) => {
        setSelectedProduct(products.find(product => product._id === productId));
        setDeletePopupOpen(true);
    };

    const confirmDelete = async () => {
        const token = await validateToken();
        if (token) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/products/${selectedProduct._id}`, {
                    params: { token }
                });
                if (response.status === 200) {
                    setNotificationMessage('Product deleted successfully');
                    setNotificationVisible(true);
                    setTimeout(() => {
                        setNotificationVisible(false);
                    }, 3000);
                    fetchProducts();
                } else {
                    console.error('Failed to delete product:', response.data.message);
                }
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        }
        setDeletePopupOpen(false);
    };

    const handleSubmit = (e, product) => {
        e.preventDefault();
        const buttonType = e.type;
        if (buttonType === 'submit') {
            if (deleteButton) {
                deleteProduct(product._id);
            } else {
                updateProduct(product);
            }
        }
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <div className="products-container">
            <Header requireSearchBar={false} />
            <div className="products-content">
                {notificationVisible && (
                    <div className={`notification ${notificationVisible ? 'show' : ''}`}>
                        {notificationMessage}
                    </div>
                )}
                {products.length < 1 ? (
                    <div>No products found.</div>
                ) : (
                    products.map(product => (
                        <form key={product._id} className="products-item" onSubmit={(e) => handleSubmit(e, product)}>
                            <h2>{product._id}</h2>
                            <label>
                                Name:
                                <input
                                    className="products-input"
                                    type="text"
                                    value={product.name}
                                    onChange={(e) => setProducts(products.map(p => p._id === product._id ? { ...p, name: e.target.value } : p))}
                                    required
                                />
                            </label>
                            <label>
                                Description:
                                <input
                                    className="products-input"
                                    type="text"
                                    value={product.description}
                                    onChange={(e) => setProducts(products.map(p => p._id === product._id ? { ...p, description: e.target.value } : p))}
                                    required
                                />
                            </label>
                            <label>
                                Price: $
                                <input
                                    className="products-input"
                                    type="number"
                                    min="0.01"
                                    step="0.01"
                                    value={product.price}
                                    onChange={(e) => setProducts(products.map(p => p._id === product._id ? { ...p, price: parseFloat(e.target.value) } : p))}
                                    required
                                />
                            </label>
                            <label>
                                Stock:
                                <input
                                    className="products-input"
                                    type="number"
                                    min="0"
                                    step="1"
                                    value={product.stock}
                                    onChange={(e) => setProducts(products.map(p => p._id === product._id ? { ...p, stock: parseInt(e.target.value, 10) } : p))}
                                    required
                                />
                            </label>
                            <label>
                                Image URL:
                                <input
                                    className="products-input"
                                    type="url"
                                    value={product.image}
                                    onChange={(e) => setProducts(products.map(p => p._id === product._id ? { ...p, image: e.target.value } : p))}
                                    required
                                />
                            </label>
                            <button type="submit" className="products-button" onClick={() => setDeleteButton(false)}>Update</button>
                            <button type="submit" className="products-button products-delete-button" onClick={() => setDeleteButton(true)}>Delete</button>
                        </form>
                    ))
                )}
            </div>
            <Popup
                open={updatePopupOpen}
                closeOnDocumentClick
                onClose={() => setUpdatePopupOpen(false)}
            >
                <div className="modal">
                    <h2 className="modal-header">Confirm Update</h2>
                    <div className="modal-content">
                        Are you sure you want to update this product?
                    </div>
                    <div className="modal-actions modal-login-link">
                        <Link onClick={confirmUpdate}>Update</Link>
                    </div>
                </div>
            </Popup>
            <Popup
                open={deletePopupOpen}
                closeOnDocumentClick
                onClose={() => setDeletePopupOpen(false)}
            >
                <div className="modal">
                    <h2 className="modal-header">Confirm Delete</h2>
                    <div className="modal-content">
                        Are you sure you want to delete this product?
                    </div>
                    <div className="modal-actions modal-login-link">
                        <Link onClick={confirmDelete}>Delete</Link>
                    </div>
                </div>
            </Popup>
            <Footer />
        </div>
    );
}

export default Products;
