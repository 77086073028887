import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Login.css';
import Footer from './components/Footer';
import './css/Popup.css';
import Popup from 'reactjs-popup';

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// If already logged in then redirect to Home page. (Also for Register page)
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
          try {
              const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
            if (response.status === 200 && response.data.status === 'success') {
                navigate('/');
            } else {
                localStorage.removeItem('token');
            }
        } catch (error) {
            console.error('Token validation error:', error);
            localStorage.removeItem('token');
        }
      }
    };

    validateToken();
  }, [navigate]);

  useEffect(() => {
    let redirectTimer;
    if (loginSuccess) {
      redirectTimer = setTimeout(() => {
        navigate('/');
      }, 3000); // Redirect after 3 seconds
    }
    return () => clearTimeout(redirectTimer);
  }, [loginSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        email,
        password,
      });

      if (response.status === 200 && response.data.status === 'success') {
        localStorage.setItem('token', response.data.token); // Save the token to local storage
        showPopup('Login successful! Welcome back to PyCommerce. You\'re now being redirected to the home page.', true);
      } else {
        showPopup('Login attempt failed. Please verify your credentials and try again or contact support if the issue persists.', false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const showPopup = (message, success) => {
    setPopupMessage(message);
    setLoginSuccess(success);
    setPopupOpen(true);
  };

  return (
      <div className='login-page'>

        <div className='content'>
          <h1 className='pycommerce-title'>
            <a href="/">PyCommerce</a>
          </h1>
          <div className='login-container'>
            <h2>Sign In</h2>
            <form onSubmit={handleSubmit}>
              <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!validateEmail(e.target.value)) {
                      e.target.setCustomValidity('Please enter a valid email address (e.g., example@domain.com)');
                    } else {
                      e.target.setCustomValidity('');
                    }
                  }}
                  required
              />
              <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
              <button type="submit">Login</button>
            </form>
            <p>
              By continuing, you agree to PyCommerce's Conditions of Use and Privacy Notice.
            </p>
            <div className="login-link">
              New to PyCommerce? <Link to="/register">Create an account</Link>
            </div>
          </div>

        </div>
        <Footer />
        <Popup
            open={popupOpen}
            closeOnDocumentClick
            onClose={() => setPopupOpen(false)}
        >
          <div className="modal">
            <h2 className="modal-header">Login Status</h2>
            <div className="modal-content">
              {popupMessage}
            </div>
            <div className="modal-actions">
              {loginSuccess ? (
                  <div className="modal-login-link">
                    <Link to="/">Redirecting...</Link>
                  </div>
              ) : (
                  <div className="modal-login-link">
                    <Link onClick={() => setPopupOpen(false)}>Close</Link>
                  </div>
              )}
            </div>
          </div>
        </Popup>
      </div>
  );
};

export default Login;