import React from 'react';
import './css/FulfillOrders.css';
import Header from './components/Header';
import Footer from './components/Footer';

function FulfillOrders() {
    return (
        <div>
            <Header requireSearchBar={false} />
            <div>FulfillOrders</div>
            <Footer />
        </div>
    );
}

export default FulfillOrders;