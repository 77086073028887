import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import axios from 'axios';
import './css/Order.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Order = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    return token;
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
        return null;
    };

    useEffect(() => {

        const fetchOrder = async () => {

            const token = await validateToken();
            if (!token) {
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${id}`, {
                    params: { token }
                });
                if (response.status === 200 && response.data.status === 'success') {
                    setOrder(response.data.order);
                    console.log(response.data.order);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching order:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrder();
    }, [id]);

    if (loading) {
        return <div></div>;
    }

    const formatStatus = (status) => {
        console.log(status);
        if (status === 'processing') {
            return 'Processing';
        } else if (status === 'shipped') {
            return 'Shipped';
        } else if (status === 'delivered') {
            return 'Delivered';
        } else if (status === 'partly_shipped') {
            return 'Partly Shipped';
        } else if (status === 'partly_delivered') {
            return 'Partly Delivered';
        }
    }

    return (
        <div id="order-page">
            <Header requireSearchBar={false} />
            <div id="order-content">
                <h1>Order Details</h1>
                <div id="order-summary">
                    <p><strong>Order ID:</strong> {order.order_id}</p>
                    <p><strong>Email:</strong> {order.email}</p>
                    <p><strong>Address:</strong> {order.address}</p>
                    <p><strong>Order Date:</strong> {new Date(order.created_at).toLocaleDateString()}</p>
                    <p><strong>Status:</strong> <span className={`order-status ${order.status}`}>{formatStatus(order.status)}</span></p>
                </div>
                <div id="order-items">
                    <h2>Items</h2>
                    {order.products.map((product, index) => (
                        <div key={index} className="order-item">
                            <p><strong>Product Name:</strong> {product.name}</p>
                            <p><strong>Product ID:</strong> {product.product_id}</p>
                            <p><strong>Quantity:</strong> {product.quantity}</p>
                            <p><strong>Status:</strong> <span className={`item-status ${product.status}`}>{formatStatus(product.status)}</span></p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Order;