import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/Checkout.css';
import Footer from './components/Footer';
import Header from './components/Header';

const Checkout = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shippingAddress, setShippingAddress] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationVisible, setNotificationVisible] = useState(false);
    const navigate = useNavigate();

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    return token;
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
        return null;
    };

    useEffect(() => {
        const fetchCartItems = async () => {
            const token = await validateToken();
            if (!token) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                    params: { token }
                });
                setCartItems(response.data.cart);
            } catch (error) {
                console.error('Error fetching cart items:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCartItems();
    }, [navigate]);

    const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const handlePlaceOrder = async (e) => {
        e.preventDefault();
        const token = await validateToken();
        if (!token) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/checkout`, {
                token,
                shippingAddress
            });

            if (response.status === 200 && response.data.status === 'success') {
                navigate(`/order/${response.data.order_id}`); 
            } else {
                setNotificationMessage(response.data.message || 'Failed to place order.');
                setNotificationVisible(true);
            }
        } catch (error) {
            console.error('Error placing order:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div id="checkout-page">
            <Header requireSearchBar={false} />
            <div id="checkout-content">
                <h1>Checkout</h1>
                {notificationVisible && (
                    <div className={`notification ${notificationVisible ? 'show' : ''}`}>
                        {notificationMessage}
                    </div>
                )}
                {cartItems.length > 0 ? (
                    <>
                        <div id="checkout-items">
                            {cartItems.map(item => (
                                <div key={item.product_id} className="checkout-item">
                                    <img src={item.image} alt={item.name} />
                                    <div className="checkout-item-details">
                                        <h2>{item.name}</h2>
                                        <p>Quantity: {item.quantity}</p>
                                        <p>Price: ${item.price.toLocaleString()}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <form id="checkout-summary" onSubmit={handlePlaceOrder}>
                            <p>Subtotal: <strong>${subtotal}</strong></p>
                            <textarea
                                placeholder="Enter your shipping address"
                                value={shippingAddress}
                                onChange={(e) => setShippingAddress(e.target.value)}
                                required
                            />
                            <button id="place-order-button" type="submit">Place Order</button>
                        </form>
                </>
                ) : (
                    <p>Your cart is empty.</p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Checkout;