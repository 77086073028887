import React from 'react';

import {HashRouter as Router, Route, Routes} from 'react-router-dom';


import Register from './pages/Register';
import Home from './pages/Home';
import Cart from './pages/Cart';
import AddItem from './pages/AddItem';
import Login from './pages/Login';
import Product from './pages/Product';
import Products from './pages/Products';
import Checkout from './pages/Checkout';
import Order from './pages/Order';
import MyOrders from './pages/MyOrders';
import FulfillOrders from './pages/FulfillOrders';
import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<Home/>}/>
                <Route path="/cart" element={<Cart/>}/>
                <Route path="/additem" element={<AddItem/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/product/:id" element={<Product/>}/>
                <Route path="/products" element={<Products/>}/>
                <Route path="/checkout" element={<Checkout/>}/>
                <Route path="/order/:id" element={<Order/>}/>
                <Route path="/myorders" element={<MyOrders/>}/>
                <Route path="/fulfillorders" element={<FulfillOrders/>}/>
            </Routes>
        </Router>
    );
}

export default App;