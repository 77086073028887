import React, { useEffect, useState } from 'react';
import Footer from './components/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../pages/css/AddItem.css';
import './css/Popup.css';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import Header from './components/Header';

const AddItem = () => {
  const [token, setToken] = useState(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [stock, setStock] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [productId, setProductId] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
          try {
              const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
              if (response.status === 200 && response.data.status === 'success') {
                  setToken(token);
                  return token;
              } else {
                  localStorage.removeItem('token');
                  navigate('/login');
              }
          } catch (error) {
              console.error('Token validation error:', error);
              localStorage.removeItem('token');
              navigate('/login');
          }
      } else {
          navigate('/login');
      }
      return null;
    };

    validateToken();
  });

  useEffect(() => {
    let redirectTimer;
    if (addSuccess) {
      redirectTimer = setTimeout(() => {
        navigate('/product/' + productId);
      }, 3000); // Redirect after 3 seconds
    }
    return () => clearTimeout(redirectTimer);
  }, [addSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products`, {
        token,
        name,
        price,
        stock,
        description,
        imageUrl
      });

      if (response.status === 200 && response.data.status === 'success') {
        showPopup('Item successfully added! Redirecting you to your product page.', true);
        console.log(response.data);
        setProductId(response.data.product_id);
      } else {
        showPopup('Failed to add item. Please try again.', false);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  }

  const showPopup = (message, success) => {
    setPopupMessage(message);
    setAddSuccess(success);
    setPopupOpen(true);
  };

  return(
    <div id="add-item-page">
      <Header requireSearchBar={false} />
      <div id='add-item-container'>
        <h2>Add Item</h2>
        <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Name" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
        <input 
          type="number" 
          placeholder="Price" 
          id="price" 
          name="price"
          step="0.01"
          min="0" 
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required />
          <input 
          type="number" 
          placeholder="Stock" 
          id="stock" 
          name="stock"
          min="0"
          step="1"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required />
        <textarea id="description" placeholder="Description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
        <input type="url" id="imageUrl" placeholder="Image URL" name="imageUrl" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} required />
          <button type="submit">Add Item</button>
        </form>
      </div>
      <Footer />
      <Popup
        open={popupOpen}
        closeOnDocumentClick
        onClose={() => setPopupOpen(false)}
      >
        <div className="modal">
          <h2 className="modal-header">Add Item Status</h2>
          <div className="modal-content">
            {popupMessage}
          </div>
          <div className="modal-actions">
            {addSuccess ? (
              <div className="modal-login-link">
                <Link to={`/product/${productId}`}>Redirecting...</Link>
              </div>
            ) : (
              <div className="modal-login-link">
                <Link onClick={() => setPopupOpen(false)}>Close</Link>
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  )
};

export default AddItem;